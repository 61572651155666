import React from 'react'
import { createBrowserRouter, RouterProvider, Route, Link } from 'react-router-dom'
import About from '@/pages/About'
import MainPage from '@/pages/index'
import Preferences from '@/pages/Preferences'
import Notification from '@/pages/Notification'
import Container from '@/components/Container'

const addLayout = (el) => {
  return <Container>{el}</Container>
}

const router = createBrowserRouter([
  {
    path: '/',
    element: addLayout(<MainPage />),
  },
  {
    path: 'about',
    element: addLayout(<About />),
  },
  {
    path: 'preferences',
    element: addLayout(<Preferences />),
  },
  {
    path: 'notification',
    element: addLayout(<Notification />),
  },
])

export default router
