import jwtDecode from 'jwt-decode'
import { useEffect, useRef } from 'react'
import { DOMAIN } from '@/config'

export const GoogleLogin = ({ className, setIsMember }) => {
  const { ipcRenderer } = window.require('electron')
  const loginEl = useRef()
  useEffect(() => {
    console.log('👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻')
    const google = window.google
    if (google) {
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        auto_select: false,
        callback: async (res) => {
          const resData = jwtDecode(res.credential)
          console.log(resData)

          if (resData.hd !== DOMAIN) {
            setIsMember(false)
            return
          }
          const { email, name } = resData
          ipcRenderer.send('login', { email, name })
        },
      })
      google.accounts.id.prompt()
      window.google.accounts.id.renderButton(loginEl.current, {
        theme: 'outline',
        size: 'large',
        text: 'login',
        width: 300,
        logo_alignment: 'center',
      })
    }
  }, [])

  return <div ref={loginEl} className={`google-login ${className}`}></div>
}
