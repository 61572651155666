const LogoSet = () => {
  return (
    <h1 className="c-logo-set">
      <span className="c-logo-set__logo">🏃🏼</span> <span className="c-logo-set__txt">JIBEGAJA</span>{' '}
      <span className="c-logo-set__sub-txt">for DFY</span>
    </h1>
  )
}

export default LogoSet
