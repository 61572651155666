import { useEffect, useState, useCallback, useMemo } from 'react'
import { Main } from '@/components/Main'
import { WorkInfo } from '@/components/WorkInfo'
import TopBar from '@/components/TopBar'
import { useLoginValue } from '@/hooks/useLoginValue'
import Loader from '@/components/Loader'

const MainPage = () => {
  const { ipcRenderer } = window.require('electron')

  const { userInfo } = useLoginValue()
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true)
    }, 300)
  })

  return (
    <>
      <TopBar />
      {isLoaded ? userInfo ? <WorkInfo /> : <Main /> : <Loader />}
    </>
  )
}

export default MainPage
