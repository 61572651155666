import { ScaleLoader } from 'react-spinners'

const Loader = () => {
  return (
    <div className="c-loader">
      <ScaleLoader color="rgba(255, 255, 255, 0.2)" />
    </div>
  )
}

export default Loader
