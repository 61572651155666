import styles from '@/styles/WarningMessage.module.scss'

export const WarningMessage = () => {
  return (
    <div className={styles['warning-msg']}>
      DFY 임직원이 아닙니다.
      <br /> 메일 주소를 확인해주세요.
    </div>
  )
}
