import styles from '@/styles/WorkInfo.module.scss'
import { useLoginValue } from '@/hooks/useLoginValue'
import axios from 'axios'
import { useEffect, useState, useMemo, useCallback } from 'react'
import {
  lastDayOfMonth,
  getDay,
  isMatch,
  subWeeks,
  getWeekOfMonth,
  parse,
  addHours,
  format,
  isEqual,
  isSameDay,
  isSaturday,
  isSunday,
  subDays,
  isAfter,
  getMonth,
  getYear,
  isBefore,
  compareDesc,
  parseISO,
  setHours,
} from 'date-fns'
import ko from 'date-fns/locale/ko'
import { BsImage } from 'react-icons/bs'
import Loader from '@/components/Loader'
import { supabase } from '@/client'

export const WorkInfo = () => {
  const { ipcRenderer } = window.require('electron')

  const { userInfo, specialDays, setSpecialDays, isWorkInfoLoaded, setIsWorkInfoLoaded, transparencyRange, setTransparencyRange } =
    useLoginValue()
  const [userData, setUserData] = useState()

  const [today, setToday] = useState(new Date())

  // Refresh Day: 월의 마지막 금요일 (마지막 금요일이 공휴일인 경우 전 주를 마지막 금요일로 세팅)
  const [holidays, setHolidays] = useState([])
  const DATE_FORMAT = 'yyyy-MM-dd'
  const TIME_FORMAT = 'HH:mm:ss'
  const TIME_FORMAT_CONVERT = 'a hh:mm'
  const [policy, setPolicy] = useState()
  const [refreshDay, setRefreshDay] = useState()
  const isRefreshDay = useMemo(() => {
    return refreshDay && isSameDay(today, refreshDay)
  }, [refreshDay, today])
  const parsedHolidayDates = useMemo(() => {
    return holidays.map((holiday) => parse(holiday.start, DATE_FORMAT, new Date()))
  }, [userData, holidays])
  const getRefreshDay = useCallback(() => {
    const lastDay = lastDayOfMonth(today)
    const dayOfWeek = 5

    const fridayHolidays = parsedHolidayDates.filter((holiday) => {
      return getDay(holiday) === dayOfWeek
    })

    let rDay = null
    let broke = false
    for (let i = lastDay.getDate(); i >= 1; i--) {
      if (broke) {
        break
      }
      const date = new Date(lastDay.getFullYear(), lastDay.getMonth(), i)
      if (getDay(date) === dayOfWeek) {
        if (!fridayHolidays.find((f) => isEqual(f, date))) {
          rDay = date
          broke = true
          break
        }
        for (let j = 0; j <= fridayHolidays.length - 1; j++) {
          if (fridayHolidays.includes(rDay) && isEqual(date, fridayHolidays[j])) {
            rDay = subWeeks(date, j + 1)
            break
          }
        }
      }
    }
    setRefreshDay(rDay)
  }, [userData, parsedHolidayDates, today])

  const [payDay, setPayDay] = useState()
  const isPayDay = useMemo(() => {
    return payDay && isSameDay(today, payDay)
  }, [payDay, today])

  // 월급날
  const getPayDay = useCallback(() => {
    const dayOffDates = [...parsedHolidayDates]

    const lastDay = lastDayOfMonth(today)

    let oDay = null
    let broke = false
    for (let i = lastDay.getDate(); i >= 1; i--) {
      if (broke) {
        break
      }
      const date = new Date(lastDay.getFullYear(), lastDay.getMonth(), i)
      if (!isSaturday(date) && !isSunday(date)) {
        if (!dayOffDates.find((f) => isEqual(f, date))) {
          oDay = date
          broke = true
          break
        }
        for (let j = 0; j <= dayOffDates.length - 1; j++) {
          if (dayOffDates.includes(oDay) && isEqual(date, dayOffDates[j])) {
            oDay = subDays(date, j + 1)
            break
          }
        }
      }
    }
    setPayDay(oDay)
  }, [userData, parsedHolidayDates, today])

  const workStartParsedTime = useMemo(() => {
    if (userData?.workDate) {
      return parse(`${userData.workDate} ${userData.workTime}`, `${DATE_FORMAT} ${TIME_FORMAT}`, new Date())
    }
  }, [isRefreshDay, userData])

  const isLate = useMemo(() => {
    if (userData?.workDate && policy) {
      return isAfter(
        workStartParsedTime,
        parse(
          `${userData.workDate} ${isRefreshDay ? policy.refreshWorkStartLimitTime : policy.workEndTime}`,
          `${DATE_FORMAT} ${TIME_FORMAT}`,
          new Date()
        )
      )
    }
  }, [userData, workStartParsedTime, policy, isRefreshDay])

  const getOffTime = useMemo(() => {
    if (userData?.workDate && policy) {
      const computedGetOffTime = addHours(workStartParsedTime, policy.workingTime)
      const standardGetOffTime = addHours(parseISO(`${format(today, DATE_FORMAT)}T${policy.workStartTime}`), policy.workingTime)
      // 출근시간이 기준 출근시간(workStartTime 보다 빠른 경우 기준 퇴근시간으로 적용)
      const isBeforeStandardGetOffTime = isBefore(computedGetOffTime, standardGetOffTime)
      return isRefreshDay
        ? isLate
          ? format(addHours(workStartParsedTime, policy.refreshDayWorkingTime), TIME_FORMAT_CONVERT)
          : format(new Date(`2023-01-01T${policy.refreshWorkEndTime}`), TIME_FORMAT_CONVERT)
        : isBeforeStandardGetOffTime
        ? format(standardGetOffTime, TIME_FORMAT_CONVERT)
        : format(computedGetOffTime, TIME_FORMAT_CONVERT)
    }
    return ''
  }, [workStartParsedTime, isRefreshDay, isLate, policy, today])

  useEffect(() => {
    ipcRenderer.send('getOffTime', getOffTime ? getOffTime : '')
  }, [getOffTime])

  const getWorkTime = useMemo(() => {
    if (userData?.workDate) {
      return format(workStartParsedTime, TIME_FORMAT_CONVERT)
    }
  }, [workStartParsedTime])

  const getWorkInfo = useCallback(async () => {
    const URI =
      process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_WORK_API_URL}/?name=${userInfo.name}` : 'http://localhost:4000/work'
    const { data } = await axios.get(URI)
    console.log(data[0])
    const { Name, WSTime, WSType } = data[0]
    const workDate = WSTime ? WSTime.split(' ')[0] : null
    const workTime = WSTime ? WSTime.split(' ')[1] : null
    const workInfo = {
      name: Name,
      workDate,
      workTime,
      workType: WSType,
    }
    setUserData(WSTime ? { ...workInfo } : { ...workInfo, workType: '결근' })
    ipcRenderer.send('workInfo', { ...workInfo })
  }, [userInfo])

  const setKoHolidays = useCallback(async () => {
    const currentYear = getYear(today)
    const currentMonth = format(today, 'MM')

    const { data: customHolidays } = await supabase
      .from('customHolidays')
      .select('summary, start, end, is_holiday, show_notification, emoji, id')
      .like('start', `${currentYear}-${currentMonth}-%`)

    ipcRenderer.send(
      'notiDays',
      customHolidays
        .filter((customHoliday) => isSameDay(parseISO(customHoliday.start), today) && customHoliday.show_notification)
        .map((customHoliday) => {
          return { id: customHoliday.id, icon: customHoliday.emoji, text: customHoliday.summary }
        })
    )

    const { data: policyData } = await supabase.from('policy').select('policy_id, time')
    setPolicy(
      policyData
        .map((policyItem) => {
          return {
            [policyItem['policy_id']]: policyItem.time,
          }
        })
        .reduce((prev, curr) => {
          return Object.assign(prev, curr)
        }, {})
    )

    const lastDay = lastDayOfMonth(today)
    const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'
    window.gapi.load('client', async () => {
      await window.gapi.client.init({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        discoveryDocs: [DISCOVERY_DOC],
      })
      const data = await window.gapi.client.calendar.events.list({
        calendarId: 'ko.south_korea#holiday@group.v.calendar.google.com',
        timeMin: today.toISOString(),
        timeMax: new Date(lastDay).toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 30,
        orderBy: 'starttime',
      })
      setHolidays(
        data.result.items
          .map((item) => ({
            summary: item.summary,
            start: item.start.dateTime || item.start.date,
            end: item.end.dateTime || item.end.date,
          }))
          .concat(customHolidays.filter((customHoliday) => customHoliday.is_holiday))
          .sort((a, b) => {
            return compareDesc(parseISO(a.start), parseISO(b.start))
          })
      )
      setIsWorkInfoLoaded(true)
    })
  }, [today])

  useEffect(() => {
    getRefreshDay()
  }, [holidays])

  useEffect(() => {
    ipcRenderer.send('fetchData')

    const fetchWorkInfoHandler = (event, command) => {
      const updatedDate = new Date()
      setToday(updatedDate)
      const useCacheFlag = command?.workDate && isSameDay(parse(command?.workDate, DATE_FORMAT, new Date()), updatedDate)
      if (!command || !useCacheFlag) {
        getWorkInfo()
      } else {
        setUserData({ ...command })
      }
    }

    ipcRenderer.on('fetchWorkInfo', fetchWorkInfoHandler)

    return () => {
      ipcRenderer.removeListener('fetchWorkInfo', fetchWorkInfoHandler)
    }
  }, [])

  useEffect(() => {
    setIsWorkInfoLoaded(false)
    if (userData?.workType === '결근') {
      setIsWorkInfoLoaded(true)
      return
    }
    if (userData) {
      setKoHolidays()
      getPayDay()
    }
  }, [userData])

  useEffect(() => {
    setSpecialDays({ ...specialDays, ...{ refresh: isRefreshDay, pay: isPayDay, late: isLate } })
  }, [isRefreshDay, isPayDay, isLate])

  function handleTransparencyChange(event) {
    const value = event.target.value
    setTransparencyRange(value)
    ipcRenderer.send('transparency', value)
  }

  const [imgWidgetSrc, setImgWidgetSrc] = useState('')
  const [isShowImgWidget, setIsShowImgWidget] = useState(false)
  const changeImgWidget = () => {
    ipcRenderer.send('changeImgWidget')
  }

  useEffect(() => {
    ipcRenderer.send('isShowImgWidget')
    ipcRenderer.send('getImgWidgetFilename')

    const isShowImgWidgetHandler = (event, command) => {
      setIsShowImgWidget(command)
    }
    const imgWidgetFilenameHandler = (event, command) => {
      command && setImgWidgetSrc(`data:image/jpeg;base64,${command}`)
    }

    ipcRenderer.on('isShowImgWidget', isShowImgWidgetHandler)
    ipcRenderer.on('imgWidgetFilename', imgWidgetFilenameHandler)

    return () => {
      ipcRenderer.removeListener('isShowImgWidget', isShowImgWidgetHandler)
      ipcRenderer.removeListener('imgWidgetFilename', imgWidgetFilenameHandler)
    }
  }, [])

  return isWorkInfoLoaded ? (
    <div className={styles['work-info']}>
      <div className={styles['work-info__view-box']}>
        <div className={`c-list ${styles['work-info__box']} ${styles['work-info-time']}`}>
          {userData?.workType !== '결근' ? (
            <>
              <div className="c-list__item">
                <span className="c-list__emoji">👩🏼‍💻</span>
                <span className="c-list__txt">출근 {getWorkTime}</span>
              </div>
              <div className="c-list__item">
                <span className="c-list__emoji">🏃🏼</span>
                <span className="c-list__txt">퇴근 {getOffTime}</span>
              </div>
            </>
          ) : (
            <div className="c-list__item">
              <span className="c-list__emoji">👀</span>
              <span className="c-list__txt">결근</span>
            </div>
          )}
        </div>
        {isShowImgWidget && (
          <div className={`${styles['work-info__img-box']} ${imgWidgetSrc ? '' : styles['s-register']}`}>
            {imgWidgetSrc && <img src={imgWidgetSrc} alt="" />}
            <button type="button" onClick={changeImgWidget} className={`${styles['work-info__img-btn']}`}>
              <BsImage />
            </button>
          </div>
        )}
      </div>

      <div className={styles['work-info-etc']}>
        <div className={styles['work-info-etc__item']}>
          <span className={styles['work-info-etc__txt']} title={userData.name}>
            {userData.name}
          </span>
        </div>
        <div className={styles['work-info-etc__item']}>
          <span className={styles['work-info-etc__txt']}>{format(today, `${DATE_FORMAT} (eee)`, { locale: ko })}</span>
        </div>
        <div className={styles['work-info-etc__item']}>
          <input
            type="range"
            id="volume"
            name="volume"
            min="1"
            max="10"
            value={transparencyRange}
            className={styles['work-info-etc__range-input']}
            onChange={handleTransparencyChange}
          />
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  )
}
