import React, { useEffect, useState, useCallback, useMemo, createContext, Suspense } from 'react'
import { LoginContext } from '@/context/LoginContext'
import { RouterProvider, useRoutes } from 'react-router-dom'
import router from '@/router'

const DesktopApp = () => {
  const { ipcRenderer } = window.require('electron')

  const [isWorkInfoLoaded, setIsWorkInfoLoaded] = useState(false)
  const [userInfo, setUserInfo] = useState(null)
  const [specialDays, setSpecialDays] = useState({
    refresh: false,
    pay: false,
    late: false,
  })
  const [transparencyRange, setTransparencyRange] = useState()

  const logout = useCallback(
    async (event) => {
      setIsWorkInfoLoaded(false)
      if (userInfo) {
        const google = window.google
        try {
          await google.accounts.id.revoke(userInfo.email, (done) => {
            console.log(done.error, 'revoke logout!!!')
            setUserInfo(null)
            event.sender.send('logout')
          })
        } catch (err) {
          console.log(err)
        }

        google.accounts.id.disableAutoSelect()
      }
    },
    [userInfo]
  )

  useEffect(() => {
    ipcRenderer.send('isWorkInfoLoaded', isWorkInfoLoaded)
  }, [isWorkInfoLoaded])

  useEffect(() => {
    ipcRenderer.on('logout', logout)

    return () => {
      ipcRenderer.removeListener('logout', logout)
    }
  }, [userInfo])

  useEffect(() => {
    ipcRenderer.on('userInfo', (event, command) => {
      setUserInfo(command)
    })
    ipcRenderer.on('transparency', (event, command) => {
      setTransparencyRange(command)
    })
  }, [])

  useEffect(() => {
    ipcRenderer.send('specialDays', specialDays)
  }, [specialDays])

  return (
    <LoginContext.Provider
      value={{
        userInfo,
        setUserInfo,
        specialDays,
        setSpecialDays,
        isWorkInfoLoaded,
        setIsWorkInfoLoaded,
        transparencyRange,
        setTransparencyRange,
      }}
    >
      <div className={'l-app'}>
        <RouterProvider router={router} />
      </div>
    </LoginContext.Provider>
  )
}

const BrowserApp = () => {
  return <div>hi guys</div>
}

const App = () => {
  const isElectron = navigator.userAgent.toLowerCase().indexOf('electron') !== -1
  !isElectron && (location.href = '/guide.html')

  return isElectron ? DesktopApp() : BrowserApp()
}

export default App
