import { useState } from 'react'
import styles from '../styles/Main.module.scss'
import { GoogleLogin } from './GoogleLogin'
import { WarningMessage } from '@/components/WarningMessage'
import { useLoginValue } from '../hooks/useLoginValue'
import LogoSet from '@/components/LogoSet'

export const Main = () => {
  const { userInfo } = useLoginValue()
  const [isMember, setIsMember] = useState(null)

  return (
    <div className={styles['main']}>
      <LogoSet />
      {!userInfo && (
        <>
          <div className={styles['main__content']}>
            {typeof isMember === 'boolean' && !isMember && <WarningMessage />}
            <div className={styles['main-login']}>
              <p className={styles['main-login__msg-google']}>Continue with Google</p>
              <span className={styles['main-login__msg-domain']}>dfy.co.kr</span>
              <GoogleLogin setIsMember={setIsMember} className={styles['main-login__google']} />
            </div>
          </div>
        </>
      )}
    </div>
  )
}
