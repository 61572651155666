import { useEffect, useState, useMemo } from 'react'
import styles from '@/styles/Notification.module.scss'
import { BsXLg } from 'react-icons/bs'
import Loader from '@/components/Loader'

const Notification = () => {
  const { ipcRenderer } = window.require('electron')

  const [specialDays, setSpecialDays] = useState()
  const [notiDays, setNotiDays] = useState()

  useEffect(() => {
    ipcRenderer.on('specialDays', (event, command) => {
      setSpecialDays({ ...command })
    })
    ipcRenderer.on('notiDays', (event, command) => {
      setNotiDays(command)
    })
  }, [])

  const closeNoti = () => {
    ipcRenderer.send('setActiveNotiModal', false)
  }

  const specialDayInfo = {
    refresh: {
      icon: '😎',
      text: '리프레시 데이',
    },
    pay: {
      icon: '💰',
      text: '월급날',
    },
    late: {
      icon: '👎🏻',
      text: '지각',
    },
  }

  const formattedSpecialDays = useMemo(() => {
    return specialDays
      ? Object.keys(specialDays)
          .filter((specialDay) => specialDays[specialDay])
          .map((specialDay) => {
            return { id: specialDay, ...specialDayInfo[specialDay] }
          })
      : []
  }, [specialDays])

  const days = useMemo(() => {
    return formattedSpecialDays && notiDays ? formattedSpecialDays.concat(notiDays) : []
  }, [formattedSpecialDays, notiDays])

  return (
    <div className={`${styles['noti']}`}>
      <div className={`${styles['noti__top-box']}`}>
        <h2 className={styles['noti__tit']}>Today is</h2>
        <button type="button" className={styles['noti__close-btn']} onClick={closeNoti}>
          <BsXLg />
        </button>
      </div>
      {days && (
        <div className={`${styles['noti__box']}`}>
          <div className={'c-list'}>
            {days.map((day) => {
              return (
                <div key={day} className="c-list__item">
                  <span className="c-list__emoji">{day.icon}</span>
                  <span className="c-list__txt">{day.text}</span>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default Notification
