import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useLoginValue } from '@/hooks/useLoginValue'

const Container = ({ children }) => {
  const location = useLocation()
  const { transparencyRange } = useLoginValue()

  const pageName = useMemo(() => {
    return location.pathname.replace(/\//g, '')
  }, [location])

  return (
    <div
      className={`l-container ${pageName ? `p-${pageName}` : 'p-main'}`}
      style={!pageName ? { '--bg-transparency': transparencyRange } : {}}
    >
      {children}
    </div>
  )
}

export default Container
