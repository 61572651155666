import LogoSet from '@/components/LogoSet'
import styles from '@/styles/About.module.scss'
import { VERSION } from '@/config'

const About = () => {
  return (
    <div className={styles['about']}>
      <LogoSet />
      <div className={styles['about__info']}>
        <span className={styles['about__version']}>{VERSION}</span>
        <div className={styles['about__created']}>
          <span className={styles['about__created-tit']}>Created by</span>
          <span className={styles['about__created-txt']}>
            권현지 <span className={styles['about__created-sub-txt']}>디파이 개발실</span>
          </span>
        </div>
        <p className={styles['about__copyright']}>Copyright © 2023 Hyeonji Kwon</p>
      </div>
    </div>
  )
}

export default About
