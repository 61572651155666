import styles from '@/styles/TopBar.module.scss'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { BsDashLg, BsXLg, BsPinAngle, BsPinAngleFill, BsArrowClockwise, BsBell, BsBellFill } from 'react-icons/bs'
import { useLoginValue } from '@/hooks/useLoginValue'

const TopBar = () => {
  const { userInfo, specialDays, isWorkInfoLoaded } = useLoginValue()

  const { ipcRenderer } = window.require('electron')
  const minimizeApp = () => {
    ipcRenderer.send('minimizeApp')
  }
  const quitApp = () => {
    ipcRenderer.send('quitApp')
  }

  const [isPin, setIsPin] = useState(false)
  const isPinHandler = (event, command) => {
    setIsPin(command)
    ipcRenderer.send('pinApp', command)
  }

  const fetchData = () => {
    ipcRenderer.send('fetchData')
  }

  const [isActiveNotiModal, setIsActiveNotiModal] = useState(false)
  const toggleNoti = () => {
    setIsActiveNotiModal(!isActiveNotiModal)
  }
  const notiHandler = (event, command) => {
    setIsActiveNotiModal(command)
  }
  const [notiDays, setNotiDays] = useState()
  const notiDaysHandler = (event, command) => {
    setNotiDays(command)
  }
  useEffect(() => {
    ipcRenderer.send('setActiveNotiModal', isActiveNotiModal)
  }, [isActiveNotiModal])

  const isSpecialDay = useMemo(() => {
    return (specialDays && !!Object.keys(specialDays).find((item) => specialDays[item])) || notiDays?.length
  }, [specialDays, notiDays])

  useEffect(() => {
    ipcRenderer.on('isPin', isPinHandler)
    ipcRenderer.on('setActiveNotiModal', notiHandler)
    ipcRenderer.on('notiDays', notiDaysHandler)

    return () => {
      ipcRenderer.removeListener('isPin', isPinHandler)
      ipcRenderer.removeListener('setActiveNotiModal', notiHandler)
      ipcRenderer.removeListener('notiDays', notiDaysHandler)
    }
  }, [])

  return (
    <div className={styles['top-bar']}>
      {userInfo && (
        <>
          <div className={`${styles['top-bar__btn-box']} ${styles['top-bar__btn-box--left']}`}>
            <button
              type="button"
              className={`${styles['top-bar__btn']} ${styles['top-bar__btn--pin']}`}
              onClick={(event) => isPinHandler(event, !isPin)}
            >
              {isPin ? <BsPinAngleFill /> : <BsPinAngle />}
            </button>
            <button
              type="button"
              className={`${styles['top-bar__btn']} ${styles['top-bar__btn--reload']}`}
              disabled={!isWorkInfoLoaded}
              onClick={fetchData}
            >
              <BsArrowClockwise />
            </button>
            <button
              type="button"
              className={`${styles['top-bar__btn']} ${styles['top-bar__btn--noti']} ${isSpecialDay ? 's-active' : ''}`}
              disabled={!isWorkInfoLoaded}
              onClick={toggleNoti}
            >
              {isActiveNotiModal ? <BsBellFill /> : <BsBell />}
            </button>
          </div>
          <div className={styles['top-bar__tit-box']}>
            <span className={styles['top-bar__tit-icon']}>🏃🏼</span> <span className={styles['top-bar__tit-txt']}>JIBEGAJA</span>
          </div>
        </>
      )}
      <div className={`${styles['top-bar__btn-box']} ${styles['top-bar__btn-box--right']}`}>
        <button type="button" className={`${styles['top-bar__btn']} ${styles['top-bar__btn-min']}`} onClick={minimizeApp}>
          <BsDashLg />
        </button>
        <button type="button" className={`${styles['top-bar__btn']} ${styles['top-bar__btn-min']}`} onClick={quitApp}>
          <BsXLg />
        </button>
      </div>
    </div>
  )
}

export default TopBar
